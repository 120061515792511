import React, { useEffect } from "react"

import SelectField from "./SelectField"

import { useCachedState } from "../../hooks/useCachedState"
import { postData } from "../../services/server"

function StateSelectField(props) {
    const [states, setStates] = useCachedState("states", []);

	useEffect(
		() => {
            if (!states || states.length === 0) {
				postData("/api/cart/states", {},
				  function(result) {
					  setStates(result);
				  },
				  function(error) {
					  console.log(error);
				  }
				);
			}			
		}, 
		[]
	);

    return (
        <SelectField 
            value={props.value}
            required={props.required}
            allowBlank={props.allowBlank}
            error={props.error}
            name={props.name}
            label={props.label}
            options={
                states.map(state => {
                    return {
                        value: state.abbrev,
                        label: state.abbrev
                    }
                })
            }
            onChange={props.onChange} 
        />
    );
}

export default StateSelectField