import React from "react"
import { faEnvelope} from "@fortawesome/free-solid-svg-icons";

import TextField from "./TextField"

function EmailField(props) {
    return (
        <TextField 
            type="email" 
            startIcon={faEnvelope}
            {...props} />
    );
}

export default EmailField