import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby";
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPlus, faCheck, faFile } from "@fortawesome/free-solid-svg-icons";
import queryString from 'query-string'
import produce from 'immer'

import Page from "../components/layout/Page"
import SEO from "../components/seo"
import PageHeader from "../components/layout/PageHeader"
import Button from "../components/buttons/Button"
import UploadButton from "../components/buttons/UploadButton"
import TextField from "../components/fields/TextField"
import RadioField from "../components/fields/RadioField"
import EmailField from "../components/fields/EmailField"
import MultiTextField from "../components/fields/MultiTextField"
import StateSelectField from "../components/fields/StateSelectField"
import GoogleImage from "../components/GoogleImage";

import { UserContext } from "../state/UserContext"
import { postData } from "../services/server"
import { colors, phones, devices } from "../settings"
import { formatPhoneNumber, parsePhoneNumber, removeNulls } from "../utilities/utils"
import { sendConversion, sendPageView } from "../services/google";
import { isValidPhoneNumber } from "../utilities/validation";

const DataForm = styled.form`
	max-width: 900px;
	margin: 30px auto;

    > div {
		display: flex;
		align-items: center;
        margin-bottom: 12px;

		@media ${devices.mobile} {
			display: block;
		}
        &.medium {
		  width: 50%;
		  
		  @media ${devices.mobile} {
			width: 65%;
		  }
        }
        &.small {
		  width: 25%;
		  
		  @media ${devices.mobile} {
			width: 35%;
		  }
		}
		
		> div.form {
			flex: 1;
			max-width: 200px;

			> div {
				width: 100%;
				margin-left: 20px;
			}
		}
		> label {
			flex: 1;
			padding: 10px 20px;
			border: 1px solid #ccc;
			max-width: 140px;
			border-radius: 5px;
			white-space: nowrap;

			@media ${devices.mobile} {
				padding: 0px;
				border: 0px;
			}
		}
	}
	> div.row {
		display: flex;

		> div {
			flex: 1;

			&:not(:last-child) {
				padding-right: 15px;
			}
		}

	}
	> div.buttons {
		margin-top: 25px;
		display: block;
	}
	div.form {
		@media ${devices.mobile} {
			padding-left: 0px !important;
			margin-right: 0px !important;
			width: 100%;
			max-width: 100% !important;	
		}	
	}
	
	> h3 {
		margin-top: 35px;
	}
`
const Project = styled.div`
	display: flex;
	margin-top: 25px;

	svg {
		color: ${colors.green};
		margin-right: 20px;
	}
`

const CustomPricePage = () => {
	const userContext = useContext(UserContext);
	const currentUser = userContext.isAuthenticated() ? userContext.profile : null;

	const [project, setProject] = useState(null);
    const [error, setError] = useState(null);
    const [busy, setBusy] = useState(false);
    const [data, setData] = useState({
	  description: "",
	  sign_type: "Other",
	  sign_text1: "",
	  sign_text2: "",
	  sign_text3: "",
	  shape: "",
	  size: "",
	  contact_name: currentUser ? currentUser.name : "",
	  contact_phoneno: currentUser ? currentUser.phoneno : "",
	  contact_email:  currentUser ? currentUser.email : "",
	  attachment1: null,
	  attachment2: null,
	  attachment3: null,
	  state: null,
    })
    const [errors, setErrors] = useState({
		description: null,
		sign_type: null,
		sign_text1: null,
		sign_text2: null,
		sign_text3: null,
		shape: null,
		size: null,
		contact_name: null,
		contact_phoneno: null,
		contact_email: null,
		attachment1: null,
		attachment2: null,
		attachment3: null,
		state: null,
	})
	
	useEffect(
		() => {
			const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");

			if (urlParams.description) {
				setData(produce(draft => {
					draft.description = urlParams.description;
				}))
			}		
		}, 
		[]
	);

    function onFieldChange(e) {
		if (e.persist) e.persist();

		let name = e.target.name;
		let value = e.target.value;

		if (name == "contact_phoneno")
			value = parsePhoneNumber(value);

		setError(null)
		setData(produce(draft => {
			draft[e.target.name] = e.target.value;
		}));
    }

    function validateForm() {
        const descriptionValid = data.description && data.description.length > 0;
        const contactNameValid = data.contact_name && data.contact_name.length > 0;
        const contactPhoneValid = isValidPhoneNumber(data.contact_phoneno);
        const contactEmailValid = data.contact_email && data.contact_email.length > 0;
        const callDiscussValid = data.call_to_discuss && data.call_to_discuss.length > 0;
		const stateValid = data.state && data.state.length > 0;

        setErrors({
			description: !descriptionValid,
			contact_name: !contactNameValid,
			contact_phoneno: !contactPhoneValid,
			contact_email: !contactEmailValid,
			call_to_discuss: !callDiscussValid,
			state: !stateValid
        });

        return descriptionValid && contactNameValid && contactPhoneValid && contactEmailValid && callDiscussValid && stateValid;
	}
	
    function onSubmit() {
        const valid = validateForm();

        if (valid) {
			setError("");
			setBusy(true);
			
			const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
			const {attachment1, attachment2, attachment3, ...formData} = data;

			if (attachment1) formData.attachment1_id = attachment1.id;
			if (attachment2) formData.attachment2_id = attachment2.id;
			if (attachment3) formData.attachment3_id = attachment3.id;

			postData("/api/projects/submit", removeNulls({
				...formData,
				from: urlParams.from || "",
				sign_id: urlParams.sid || null,
				user_id: currentUser ? currentUser.id : 0,
				current_user_id: currentUser ? currentUser.id : 0,
				email: currentUser ? currentUser.email : ""
			}), 
			(response) => {
				sendConversion("AW-946697273/3z09CMqugZkBELnotcMD");
				// manual page view for analytics conversion tracking
				sendPageView("/sign-price-requested");

				setProject(response);
			}, 
			(error, status) => {
				setError("An error occured while saving your price request.  Please give us a call at " + phones.sales + ".")
			},
			() => {
				setBusy(false);
			}
		);
		}
		else {
			setError("Please correct the highlighted problems.")
		}
	}
	
  return (
	<>
		<SEO page="customprice" title="Submit Your Sign Design for Price" />
		<Page
			breadcrumb={[
				{title: "Home", url:"/"},
				{title: "Design & Price Sign", url:"/builder"},
				{title: "Sign Price Request", url:null}
			]}
		>
			<PageHeader title="Sign Price Request" />

			{project ? 
				<Project>
					<FontAwesomeSvgIcon icon={faCheck} size="5x" />
					<div>
						<p>
							We've received your request.  We'll contact you soon with your sign price.
						</p>
						<p>
							Your price request number is <strong>#{project.id}</strong>.
						</p>
						<p>
							You can access your price request via the following link.  We've also emailed
							it to you.
						</p>
						<p>
							<Link to={"/account/project/?uid=" + project.uid}>
								{process.env.GATSBY_SITE_URL}/account/project/?uid={project.uid}
							</Link>
						</p>
					</div>
				</Project>
			:
				<DataForm>
				<p>
					Use the form below to get a price for a custom sign that can't be created in our 
					online <Link to="/builder/?from=CustomPricePrompt">Sign Builder</Link>.
				</p>

				<div>
					<MultiTextField 
						value={data.description}
						wide
						error={errors.description === true}
						id="description"
						name="description"
						label="Sign Description"
						required
						rows={5}
						onChange={onFieldChange} 
					/>
				</div>

				<h3>What kind of sign do you need?</h3>
				<div>
					<RadioField
						name="sign_type"
						wide 
						label="Sign Type"
						value={data.sign_type}
						onChange={onFieldChange}
						options={[
							{value:"Channel Letters", label:"Channel Letters"},
							{value:"Logo Shape", label:"Logo Shape"},
							{value:"Both Letters & Shape", label:"Both Letters & Shape"},
							{value:"Cabinet Sign", label:"Cabinet Sign"},
							{value:"Other", label:"Other"},
						]}
					/>
				</div>
				
				{ (data.sign_type === "Channel Letters" || data.sign_type === "Both Letters & Shape") && 
					<>
						<h3>Channel Letters</h3>
						<div>
							<TextField 
								value={data.sign_text1}
								wide
								error={errors.sign_text1 === true}
								id="sign_text1"
								name="sign_text1"
								label="Sign Text 1"
								onChange={onFieldChange} 
							/>
						</div>
						<div>
							<TextField 
								value={data.sign_text2}
								wide
								error={errors.sign_text2 === true}
								id="sign_text2"
								name="sign_text2"
								label="Sign Text 2"
								onChange={onFieldChange} 
							/>
						</div>
						<div>
							<TextField 
								value={data.sign_text3}
								wide
								error={errors.sign_text3 === true}
								id="sign_text3"
								name="sign_text3"
								label="Sign Text 3"
								onChange={onFieldChange} 
							/>
						</div>
					</>
				}
				{ (data.sign_type === "Logo Shape" || data.sign_type === "Cabinet Sign" || data.sign_type === "Both Letters & Shape") && 
					<>
						<h3>{data.sign_type === "Cabinet Sign" ? "Cabinet":"Logo"} Shape</h3>
						<div>
							<TextField 
								value={data.shape}
								wide
								error={errors.shape === true}
								id="shape"
								name="shape"
								label={(data.sign_type === "Cabinet Sign" ? "Cabinet":"Logo") + " Description"}
								onChange={onFieldChange} 
							/>
						</div>
					</>
				}

				<h3>How big is the sign?</h3>
				<div>
					<TextField 
						value={data.size}
						wide
						error={errors.size === true}
						id="size"
						name="size"
						label="Sign Size"
						helperText="Provide dimensions of the actual sign or the available area for the sign."
						onChange={onFieldChange} 
					/>
				</div>

				<h3>Do you have any existing art work?</h3>
				<div>
					<label>Attachment 1</label>
					<UploadButton 
						uploadUrl="/api/projects/upload"
						style={{paddingLeft:"20px", marginLeft: "-20px", marginRight:"20px"}}
						onUpload={(upload) => {
							setData(produce(draft => {
								draft.attachment1 = upload
							}));
						}}
					/>
					{ data.attachment1 && 
						<>
							{data.attachment1.url_thumb ? 
								<GoogleImage root src={data.attachment1.url_thumb} required lazy style={{height: "25px", width: "auto"}} />
								:
								<FontAwesomeSvgIcon icon={faFile} />
							}
							&nbsp;
							{data.attachment1.filename}
						</>
					}
				</div>
				<div>
					<label>Attachment 2</label>
					<UploadButton 
						uploadUrl="/api/projects/upload"
						style={{paddingLeft:"20px", marginLeft: "-20px", marginRight:"20px"}}
						onUpload={(upload) => {
							setData(produce(draft => {
								draft.attachment2 = upload
							}));

						}}
					/>
					{ data.attachment2 && 
						<>
							{data.attachment2.url_thumb ? 
								<GoogleImage root src={data.attachment2.url_thumb} required lazy style={{height: "25px", width: "auto"}} />
								:
								<FontAwesomeSvgIcon icon={faFile} />
							}
							&nbsp;
							{data.attachment2.filename}
						</>
					}
				</div>
				<div>
					<label>Attachment 3</label>
					<UploadButton 
						uploadUrl="/api/projects/upload"
						style={{paddingLeft:"20px", marginLeft: "-20px", marginRight:"20px"}}
						onUpload={(upload) => {
							setData(produce(draft => {
								draft.attachment3 = upload
							}));
						}}
					/>
					{ data.attachment3 && 
						<>
							{data.attachment3.url_thumb ? 
								<GoogleImage root src={data.attachment3.url_thumb} required lazy style={{height: "25px", width: "auto"}} />
								:
								<FontAwesomeSvgIcon icon={faFile} />
							}
							&nbsp;
							{data.attachment3.filename}
						</>
					}
				</div>

				<h3>
					Contact Information
				</h3>
				<p>
					Please provide the following information for us to contact you.
				</p>

				<div className="row">
					<div>
						<TextField 
							value={data.contact_name}
							wide
							error={errors.contact_name === true}
							id="contact_name"
							name="contact_name"
							label="Your Name"
							onChange={onFieldChange} 
							required 
							autoComplete="name"
						/>
					</div>
					<div>
						<TextField 
							value={formatPhoneNumber(data.contact_phoneno)}
							wide
							error={errors.contact_phoneno === true}
							id="contact_phoneno"
							name="contact_phoneno"
							placeholder="(###) ###-####"
							label="Your Phone Number"
							onChange={onFieldChange} 
							required 
							autoComplete="tel"
						/>
					</div>
					<div>
						<EmailField 
							value={data.contact_email}
							wide
							error={errors.contact_email === true}
							id="contact_email"
							name="contact_email"
							label="Your Email Address"
							onChange={onFieldChange} 
							required 
							autoComplete="email"
						/>
					</div>
				</div>

				<h3>
					Call me to discuss this project?
				</h3>

				<div>
					<label style={{display: "flex", alignItems: "center", marginRight: "10px", maxWidth: "100px", borderColor: errors.call_to_discuss === true ? "red":"initial"}}>
                        <input 
                            type="radio" 
                            name="call_to_discuss"
                            value="Yes"
                            checked={data.call_to_discuss == "Yes"}
                            onChange={onFieldChange}
                        />
                        Yes
                    </label>
					<label style={{display: "flex", alignItems: "center", marginRight: "10px", maxWidth: "100px", borderColor: errors.call_to_discuss === true ? "red":"initial"}}>
                        <input 
                            type="radio" 
                            name="call_to_discuss"
                            value="No"
                            checked={data.call_to_discuss == "No"}
                            onChange={onFieldChange}
                        />
                        No
                    </label>
				</div>

				<h3>
					What state are you located?
				</h3>
				<div>
					<StateSelectField 
						value={data.state}
						required
						allowBlank
						error={errors.state === true}
						name="state"
						label="State"
						onChange={onFieldChange} 
					/>
				</div>

				<div className="buttons">
					{error && 
						<p style={{color:"red"}}>
							{error}
						</p>
					}
					<Button 
						label="Submit Request"
						icon={faPlus}
						busy={busy}
						color="blue"
						size="large"
						onClick={onSubmit}
					/>
				</div>
			</DataForm>
			}
		</Page>
	</>
  )
}

export default CustomPricePage