import React, { useState } from "react"

import Field from "./Field"

export default (props) => {
    const [selected, setSelected] = useState(props.value);

    return (
        <Field {...props}>
            <div>
                { props.options.map((option, index) => (
                    <label style={{display: "flex", marginRight: "10px"}}>
                        <input 
                            type="radio" 
                            name={props.name} 
                            key={index} 
                            value={option.value} 
                            checked={selected == option.value}
                            onChange={(e) => {
                                setSelected(option.value);
                                props.onChange(e)
                            }}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            {props.children}
        </Field>
    );
}
